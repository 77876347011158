import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import MuiSelect from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import MaterialReactTable from 'material-react-table';
import {
    getAccounts,
    getCountries,
    getPurchaseHistory,
    getVintages,
    createAccount,
    updateAccount,
    deleteAccount,
    createPurchaseHistory,
    updatePurchaseHistory,
    deletePurchaseHistory,
    getAllAccountRoles,
} from '../../_action/site.action';
import { AlertDialog } from '../main/main';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import { MultiSelect } from 'react-multi-select-component';
import { validateAndHandleFormChange, formatISOToDateTimeUnit, generateRandomPassword } from '../../_helpers/utilities';
import Draggable from 'react-draggable';
import Select from 'react-select';

function PaperComponent(props) {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const AccountFilterDialog = (props) => {
    const { filterOpen, handleFilterClose, vintageList, vintageFilter, setVintageFilter, fullDataAccessFilter, setFullDataAccessFilter } = props;

    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth={true}
            open={filterOpen}
        >
            <DialogTitle>
                Package Filter{' '}
                <IconButton
                    aria-label='close'
                    onClick={() => handleFilterClose('CLOSE')}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography
                    gutterBottom
                    style={{ margin: '1rem 0 .5rem' }}
                >
                    Last Purchased Vintage
                </Typography>
                <MuiSelect
                    className='basic-single'
                    classNamePrefix='select'
                    isClearable={true}
                    isSearchable={true}
                    name='vintageFilter'
                    value={vintageFilter}
                    onChange={(e) => setVintageFilter(e)}
                    options={vintageList}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />

                <Typography
                    gutterBottom
                    style={{ margin: '1rem 0 .5rem' }}
                >
                    Full Data Access
                </Typography>
                <MuiSelect
                    className='basic-single'
                    classNamePrefix='select'
                    isClearable={true}
                    isSearchable={false}
                    name='fullDataAccessFilter'
                    value={fullDataAccessFilter}
                    onChange={(e) => setFullDataAccessFilter(e)}
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ]}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleFilterClose('RESET')}>Reset</Button>
                <Button onClick={() => handleFilterClose('SAVE')}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

const EditAccountDialog = (props) => {
    const {
        editAccountOpen,
        mode,
        target,
        purchasableVintageList,
        handleFormChange,
        handleEditAccountClose,
        countryList,
        handleResetPassword,
        resetPassword,
        accountRoles,
    } = props;

    const [showPassword, setShowPassword] = useState(false);
    const [validateForm, setValidateForm] = useState(false);
    const MaintenanceList = [
        { label: 'None', value: '' },
        { label: 'Quarterly', value: 'Q' },
        { label: 'Semi-Annually', value: 'S' },
        { label: 'Annually', value: 'A' },
    ];

    React.useEffect(() => {
        setShowPassword(false);
    }, [editAccountOpen]);

    React.useEffect(() => {
        setValidateForm(!target.ClientId || !target.EmailAddress.length || emailError || clientIdError || missingPrefix || customVintagePrefixError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const editMode = mode === 'Edit';
    const [emailError, setEmailError] = useState(null);
    const [clientIdError, setClientIdError] = useState(null);
    const [customVintagePrefixError, setCustomVintagePrefixError] = useState(null);

    const isFullDataAccess = target.Roles.includes('FUL_DAT_AC');

    const enableCustomVintagePrefix = target.Roles.some((role) => role.includes('CUS_UP_'));

    const missingPrefix = enableCustomVintagePrefix && !target.CustomVintagePrefix;

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={editAccountOpen}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
        >
            <DialogTitle
                style={{ cursor: 'move' }}
                id='draggable-dialog-title'
            >
                {mode} Account
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={() => {
                    handleEditAccountClose('CANCEL');
                    setClientIdError(null);
                    setCustomVintagePrefixError(null);
                    setEmailError(null);
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                id='outlined-adornment-clientId'
                                label='Client ID'
                                error={clientIdError ? true : false}
                                required
                                value={target.ClientId}
                                onChange={(e) => handleFormChange('ClientId', e, setClientIdError)}
                                helperText={clientIdError}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                id='outlined-adornment-email'
                                error={emailError ? true : false}
                                label='Email'
                                required
                                value={target.EmailAddress}
                                onChange={(e) => handleFormChange('EmailAddress', e, setEmailError)}
                                helperText={emailError}
                            />
                        </FormControl>
                    </Grid>
                    {(resetPassword || mode === 'Create') && (
                        <Grid
                            item
                            xs={12}
                        >
                            <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                            >
                                <TextField
                                    id='outlined-adornment-password'
                                    type={showPassword ? 'text' : 'password'}
                                    required
                                    label='Password'
                                    value={target.Password}
                                    onChange={(e) => handleFormChange('Password', e)}
                                    placeholder={editMode ? 'Enter new password' : ''}
                                    InputProps={{
                                        endAdornment: target.Password.length ? (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                >
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                id='outlined-adornment-clientName'
                                label='Client Name'
                                value={target.ClientName}
                                onChange={(e) => handleFormChange('ClientName', e)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <TextField
                                id='outlined-adornment-roles'
                                label={'Roles'}
                                select
                                input={
                                    <OutlinedInput
                                        id='select-multiple-chip'
                                        label='Chip'
                                    />
                                }
                                SelectProps={{
                                    multiple: true,
                                    value: target.Roles,
                                    onChange: (e) => {
                                        handleFormChange('Roles', e.target.value);
                                        if (e.target.value.length === 1 && e.target.value.includes('FUL_DAT_AC')) {
                                            target.CustomVintagePrefix = '';
                                            setCustomVintagePrefixError(null);
                                        }
                                    },
                                    renderValue: (selected) => {
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {Object.entries(accountRoles).map(
                                                    // eslint-disable-next-line array-callback-return
                                                    ([key, value]) => {
                                                        if (selected.includes(key)) {
                                                            return (
                                                                <Chip
                                                                    key={key}
                                                                    label={value}
                                                                />
                                                            );
                                                        }
                                                    },
                                                )}
                                            </Box>
                                        );
                                    },
                                }}
                            >
                                {Object.entries(accountRoles).map(([key, value]) => (
                                    <MenuItem
                                        key={key}
                                        value={key}
                                    >
                                        <ListItemText primary={value} />
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    {
                        <Grid
                            item
                            xs={12}
                        >
                            <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                            >
                                <TextField
                                    id='outlined-adornment-custom-vintage-prefix'
                                    error={customVintagePrefixError ? true : false}
                                    label='Custom Vintage Prefix'
                                    disabled={!enableCustomVintagePrefix ? true : false}
                                    required
                                    value={target.CustomVintagePrefix}
                                    onChange={(e) => handleFormChange('CustomVintagePrefix', e.target.value, setCustomVintagePrefixError)}
                                    sx={enableCustomVintagePrefix ? {} : { backgroundColor: '#0000001f' }}
                                    helperText={customVintagePrefixError}
                                />
                            </FormControl>
                        </Grid>
                    }
                    {!isFullDataAccess && mode === 'Create' && (
                        <>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                >
                                    <TextField
                                        label='Purchased Vintage'
                                        select
                                        value={purchasableVintageList.find((v) => v.label === target.VintageId)}
                                        onChange={(e) => handleFormChange('VintageId', e)}
                                        options={purchasableVintageList}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                        defaultValue={''}
                                    >
                                        {purchasableVintageList.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                >
                                    <TextField
                                        label='Maintenance Plan'
                                        select
                                        value={MaintenanceList.find((v) => v.label === target.MaintenanceFrequency)}
                                        onChange={(e) => handleFormChange('MaintenanceFrequency', e)}
                                        options={MaintenanceList}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                        defaultValue={''}
                                    >
                                        {MaintenanceList.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <Autocomplete
                                    freeSolo={true}
                                    id='country-select'
                                    onChange={(event, value) => handleFormChange('Countries', value)}
                                    sx={{ m: 1 }}
                                    fullWidth
                                    options={countryList}
                                    multiple
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <Box
                                            component='li'
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                        >
                                            {option.label}
                                        </Box>
                                    )}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                label='Country'
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                {!resetPassword && editMode && <Button onClick={() => handleResetPassword(resetPassword)}>Reset Password</Button>}
                <div style={{ flex: '1 0 0' }} />
                {editMode ? (
                    <>
                        <Button
                            onClick={() => {
                                handleEditAccountClose('CANCEL');
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleEditAccountClose('SAVE')}
                            disabled={validateForm}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <Button
                        onClick={() => handleEditAccountClose('SAVE')}
                        disabled={validateForm}
                        variant='contained'
                        sx={{ marginBottom: '10px', marginRight: '10px' }}
                    >
                        Create my account
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const EditHistoryDialog = (props) => {
    const { editHistoryOpen, mode, targetHistory, purchasableVintageList, countryList, handleFormChange, handleEditHistoryClose } = props;

    const MaintenanceList = [
        { label: 'None', value: '' },
        { label: 'Quarterly', value: 'Q' },
        { label: 'Semi-Annually', value: 'S' },
        { label: 'Annually', value: 'A' },
    ];

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={editHistoryOpen}
        >
            <DialogTitle>{mode} RECORD</DialogTitle>
            <DialogContent sx={{ paddingTop: '20px!important' }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{ marginBottom: '15px' }}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '1rem 0 .5rem' }}
                            >
                                Purchased Vintage
                            </Typography>
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                isDisabled={mode === 'UPDATE' ? true : false}
                                name='VintageId'
                                value={purchasableVintageList.find((v) => v.value === targetHistory.VintageId)}
                                onChange={(e) => handleFormChange('VintageId', e)}
                                options={purchasableVintageList}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                defaultValue=''
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{ marginBottom: '15px' }}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '1rem 0 .5rem' }}
                            >
                                Maintenance Plan
                            </Typography>
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={false}
                                name='MaintenancePlan'
                                value={MaintenanceList.find((v) => v.value === targetHistory.MaintenanceFrequency)}
                                onChange={(e) => handleFormChange('MaintenanceFrequency', e)}
                                options={MaintenanceList}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                defaultValue=''
                            />
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            fullWidth
                            sx={{ m: 1 }}
                            id={'Country_Select'}
                        >
                            <Typography
                                gutterBottom
                                style={{ margin: '1rem 0 .5rem' }}
                            >
                                Country
                            </Typography>
                            <MultiSelect
                                options={countryList}
                                value={targetHistory.Countries}
                                onChange={(e) => handleFormChange('Countries', e)}
                                labelledBy={'Select Country'}
                                overrideStrings={{
                                    allItemsAreSelected: 'All countries are selected.',
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleEditHistoryClose('CANCEL')}>Cancel</Button>
                <Button
                    onClick={() => handleEditHistoryClose('SAVE')}
                    disabled={!targetHistory.VintageId || !targetHistory.MaintenanceFrequency || targetHistory.Countries.length === 0}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DeleteAccountDialog = (props) => {
    const { deleteAccountOpen, target, handleAccountDeleteClose } = props;
    const [value, setValue] = useState('');
    const disableDelete = value !== target.ClientId;
    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={deleteAccountOpen}
        >
            <DialogTitle>Delete Account?</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <Alert
                            severity='warning'
                            sx={{ fontSize: '16px', padding: '6px' }}
                        >
                            This will permanently delete the account. You cannot undo this action.
                        </Alert>
                        <br />
                        <Typography>
                            Enter the client ID "<b>{target.ClientId}</b>" to confirm.
                        </Typography>
                        <TextField
                            fullWidth
                            margin='dense'
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleAccountDeleteClose('CANCEL');
                        setValue('');
                    }}
                >
                    CANCEL
                </Button>
                <Button
                    onClick={() => {
                        handleAccountDeleteClose('CONFIRM');
                        setValue('');
                    }}
                    variant='contained'
                    sx={{ backgroundColor: '#C62828', color: '#FFFFFF' }}
                    disabled={disableDelete}
                >
                    DELETE ACCOUNT
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DeletePurchaseHistoryDialog = (props) => {
    const { deleteHistoryOpen, targetHistory, handleHistoryDeleteClose } = props;
    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={deleteHistoryOpen}
        >
            <DialogTitle>Delete Vintage Purchase History</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography>
                            Are you sure you wish to delete vintage purchase history <b>{targetHistory.VintageName}</b> ?
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleHistoryDeleteClose('CANCEL')}>Cancel</Button>
                <Button onClick={() => handleHistoryDeleteClose('CONFIRM')}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

const PurchaseHistoryDialog = (props) => {
    const {
        purchaseHistoryDialogOpen,
        setPurchaseHistoryDialogOpen,
        targetAccount,
        purchasableVintageList,
        countryList,
        refreshAccountTable,
        setAlertOpen,
        setAlertTitle,
        setAlertContent,
    } = props;

    const [purchaseHistoryData, setPurchaseHistoryData] = useState([]);

    const historyClass = {
        VintageId: '',
        MaintenanceFrequency: '',
        Countries: [],
    };

    const [targetHistory, setTargetHistory] = useState(historyClass);
    const [editHistoryOpen, setEditHistoryOpen] = useState(false);
    const [deleteHistoryOpen, setDeleteHistoryOpen] = useState(false);
    const [mode, setMode] = useState(null);

    const [loading, setLoading] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                header: 'Date Purchased',
                accessorKey: 'PurchaseDate',
                size: 100,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell, row }) => {
                    if (row.index === 0) {
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm')}
                                <span
                                    style={{
                                        padding: '0.2em 0.6em 0.3em',
                                        fontSize: '10px',
                                        backgroundColor: '#5cb85c',
                                        color: 'white',
                                        fontWeight: 700,
                                    }}
                                >
                                    Displaying
                                </span>
                            </div>
                        );
                    } else {
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm')}
                            </div>
                        );
                    }
                },
            },
            {
                header: 'Purchased Vintage',
                accessorKey: 'VintageName',
                size: 100,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
            },
            {
                header: 'Maintenance Plan',
                accessorKey: 'MaintenanceFrequency',
                size: 150,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell, row }) => {
                    if (cell.getValue() === '' || cell.getValue() == null) {
                        return '';
                    } else if (cell.getValue() === 'Q') {
                        return 'Quarterly';
                    } else if (cell.getValue() === 'S') {
                        return 'Semi-Annually';
                    } else if (cell.getValue() === 'A') {
                        return 'Annually';
                    }
                },
            },
            {
                header: 'Country',
                accessorKey: 'Countries',
                size: 100,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell, row }) => {
                    if (cell.getValue()) {
                        let countryStr = '';
                        if (row.original.Countries) {
                            let countryList = row.original.Countries.split(',');
                            if (countryList.length > 3) {
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                countryStr = (
                                    <a>
                                        <span
                                            style={{ marginLeft: '5px' }}
                                            title={row.original.Countries}
                                        >
                                            {countryList.length} Countries
                                        </span>
                                    </a>
                                );
                            } else {
                                countryStr = <span style={{ marginLeft: '5px' }}>{row.original.Countries}</span>;
                            }
                        }
                        return countryStr;
                    } else {
                        return '';
                    }
                },
            },
            {
                header: '',
                accessorKey: 'actions',
                size: 50,
                enableHiding: false,
                enableColumnFilter: false,
                muiTableBodyCellProps: { align: 'center' },
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <IconButton
                            title={'Edit Account'}
                            onClick={() => handleEditPurchaseHistory(row.original)}
                            sx={{ padding: 0, margin: '0 10px' }}
                        >
                            <EditIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <IconButton
                            title={'Delete Account'}
                            onClick={() => handleDeletePurchaseHistory(row.original)}
                            sx={{ padding: 0, margin: '0 10px' }}
                        >
                            <DeleteIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </>
                ),
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ],
        [],
    );

    React.useEffect(() => {
        if (purchaseHistoryDialogOpen && targetAccount) {
            refreshPurchaseHistoryTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseHistoryDialogOpen]);

    const refreshPurchaseHistoryTable = () => {
        setLoading(true);
        getPurchaseHistory(targetAccount).then(
            (res) => {
                setPurchaseHistoryData(res.data);
                setLoading(false);
            },
            (err) => {
                setPurchaseHistoryData([]);
                setLoading(false);
            },
        );
    };

    const handleFormChange = (key, e) => {
        let t = { ...targetHistory };
        if (key === 'VintageId' || key === 'MaintenanceFrequency') {
            if (e) {
                t[key] = e.value;
            } else {
                t[key] = null;
            }
        } else if (key === 'Countries') {
            t[key] = e;
        } else {
            t[key] = e.target.value;
        }
        setTargetHistory(t);
    };

    const handleAddRecord = () => {
        setMode('ADD');
        setTargetHistory(historyClass);
        setEditHistoryOpen(true);
    };

    const handleEditPurchaseHistory = (data) => {
        setMode('UPDATE');
        let d = { ...data };
        if (data.Countries !== null) {
            let countries = data.Countries.split(',');
            let selected = countryList.filter((c) => countries.includes(c.value));
            d.Countries = selected;
        } else {
            d.Countries = [];
        }
        setTargetHistory(d);
        setEditHistoryOpen(true);
    };

    const handleDeletePurchaseHistory = (data) => {
        setTargetHistory(data);
        setDeleteHistoryOpen(true);
    };

    const handleEditHistoryClose = (option) => {
        if (option === 'SAVE') {
            if (mode === 'ADD') {
                let data = {
                    AccountId: targetAccount,
                    VintageId: targetHistory.VintageId,
                    MaintenanceFrequency: targetHistory.MaintenanceFrequency,
                    Countries: targetHistory.Countries.map((c) => c.value).toString(),
                };
                createPurchaseHistory(data).then(
                    (res) => {
                        setTargetHistory(historyClass);
                        setMode(null);
                        setEditHistoryOpen(false);
                        refreshPurchaseHistoryTable();
                        refreshAccountTable();
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data) {
                            message = err.response.data;
                        }
                        setAlertContent(message);
                    },
                );
            } else {
                let data = {
                    AccountId: targetAccount,
                    VintageId: targetHistory.VintageId,
                    MaintenanceFrequency: targetHistory.MaintenanceFrequency,
                    Countries: targetHistory.Countries.map((c) => c.value).toString(),
                };
                updatePurchaseHistory(targetHistory.Id, data).then(
                    (res) => {
                        setTargetHistory(historyClass);
                        setMode(null);
                        setEditHistoryOpen(false);
                        refreshPurchaseHistoryTable();
                        refreshAccountTable();
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data) {
                            message = err.response.data;
                        }
                        setAlertContent(message);
                    },
                );
            }
        } else {
            setMode(null);
            setTargetHistory(historyClass);
            setEditHistoryOpen(false);
        }
    };

    const handleHistoryDeleteClose = (option) => {
        if (option === 'CONFIRM') {
            deletePurchaseHistory(targetHistory.Id).then(
                (res) => {
                    setTargetHistory(historyClass);
                    setDeleteHistoryOpen(false);
                    refreshPurchaseHistoryTable();
                    refreshAccountTable();
                },
                (err) => {
                    setAlertOpen(true);
                    setAlertTitle('Error!');
                    let message = '';
                    if (err.response.data) {
                        message = err.response.data;
                    }
                    setAlertContent(message);
                },
            );
        } else {
            setTargetHistory(historyClass);
            setDeleteHistoryOpen(false);
        }
    };

    return (
        <>
            <Dialog
                maxWidth={'lg'}
                fullWidth={true}
                open={purchaseHistoryDialogOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {'Vintage Purchased History'}
                    <IconButton
                        id={'closeButton'}
                        aria-label='close'
                        onClick={() => setPurchaseHistoryDialogOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <MaterialReactTable
                        columns={columns}
                        data={purchaseHistoryData}
                        enableTopToolbar={true}
                        enableStickyHeader={true}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableHiding={false}
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                            sorting: [{ id: 'PurchaseDate', desc: true }],
                        }}
                        state={{ isLoading: loading }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '500px' },
                        }}
                        renderTopToolbarCustomActions={() => (
                            <div>
                                <IconButton
                                    variant='outlined'
                                    title={'Add Record'}
                                    onClick={() => handleAddRecord()}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        )}
                    />
                </DialogContent>
            </Dialog>
            <EditHistoryDialog
                mode={mode}
                targetHistory={targetHistory}
                editHistoryOpen={editHistoryOpen}
                purchasableVintageList={purchasableVintageList}
                countryList={countryList}
                handleFormChange={handleFormChange}
                handleEditHistoryClose={handleEditHistoryClose}
            />
            <DeletePurchaseHistoryDialog
                deleteHistoryOpen={deleteHistoryOpen}
                targetHistory={targetHistory}
                handleHistoryDeleteClose={handleHistoryDeleteClose}
            />
        </>
    );
};

const Account = () => {
    const [data, setData] = useState([]);
    const [filteredData, _setFilteredData] = useState([]);
    const filteredDataRef = React.useRef(filteredData);
    const setFilteredData = (x) => {
        filteredDataRef.current = x;
        _setFilteredData(x);
    };

    const [mode, setMode] = useState(null);

    const [filterOpen, setFilterOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState(null);
    const [alertContent, setAlertContent] = useState(null);

    const [loading, setLoading] = useState(false);

    const [editAccountOpen, setEditAccountOpen] = useState(false);

    const [resetPassword, setResetPassword] = useState(false);
    const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

    const [purchasableVintageList, setPurchasableVintageList] = useState([]);
    const [vintageList, setVintageList] = useState([]);
    const [vintageFilter, setVintageFilter] = useState(null);
    const [fullDataAccessFilter, setFullDataAccessFilter] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [accountRoles, setAccountRoles] = useState({});

    const accountClass = {
        ClientId: '',
        EmailAddress: '',
        Password: '',
        ClientName: '',
        VintageId: '',
        PurchasedVintage: '',
        MaintenanceFrequency: '',
        CustomVintagePrefix: '',
        Countries: [],
        DataPrivilege: false,
        Roles: [],
    };
    const [target, setTarget] = useState(accountClass);
    const [targetAccount, setTargetAccount] = useState(null);

    const [purchaseHistoryDialogOpen, setPurchaseHistoryDialogOpen] = useState(false);

    const columns = [
        { header: 'Client ID', accessorKey: 'ClientId', size: 100 },
        {
            header: 'Client Name',
            accessorKey: 'ClientName',
            size: 100,
            Cell: ({ cell, row }) => row.original.ClientName,
        },
        {
            header: 'Roles',
            size: 80,
            Cell: ({ cell, row }) => {
                return (
                    <Tooltip title={`${row.original.Roles.map((r) => accountRoles[r])}`}>
                        <div
                            style={{
                                display: 'block',
                                width: '120px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {row.original.Roles.map((r) => accountRoles[r])}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            header: 'Email',
            accessorKey: 'EmailAddress',
            size: 100,
            // muiTableHeadCellProps: { align: 'left' },
            // muiTableBodyCellProps: { align: 'left' },
            Cell: ({ cell, row }) => {
                if (cell.getValue()) {
                    return (
                        <span
                            style={{ marginLeft: '5px', display: 'block', textAlign: 'left' }}
                            title={row.original.EmailAddress}
                        >
                            {cell.getValue()}
                        </span>
                    );
                } else {
                    return null;
                }
            },
        },

        {
            header: 'Last Purchased Vintage',
            accessorKey: 'PurchasedVintage',
            size: 150,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'left' },
            Cell: ({ cell, row }) => {
                if (row.original.Roles.includes('FUL_DAT_AC')) {
                    return '';
                } else {
                    if (cell.getValue() !== null && cell.getValue() !== '') {
                        let countryStr = '';
                        if (row.original.Countries) {
                            let countryList = row.original.Countries.split(',');
                            if (countryList.length > 3) {
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                countryStr = (
                                    <a>
                                        <span
                                            style={{ marginLeft: '5px' }}
                                            title={row.original.Countries}
                                        >
                                            {countryList.length} Countries
                                        </span>
                                    </a>
                                );
                            } else {
                                countryStr = <span style={{ marginLeft: '5px' }}>{row.original.Countries}</span>;
                            }
                        }
                        return (
                            <>
                                <IconButton
                                    title={'Show full history'}
                                    onClick={() => handleOpenFullHistory(row.original.Id)}
                                    sx={{ padding: 0, margin: '0 10px' }}
                                >
                                    <HistoryIcon sx={{ fontSize: '20px' }} />
                                </IconButton>
                                <span>{cell.getValue()}</span>
                                {countryStr}
                            </>
                        );
                    } else {
                        return (
                            <IconButton
                                title={'Add vintage record'}
                                onClick={() => handleOpenFullHistory(row.original.Id)}
                                sx={{ padding: 0, margin: '0 10px' }}
                            >
                                <MoreTimeIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                        );
                    }
                }
            },
        },
        {
            header: 'Maintenance Plan',
            accessorKey: 'MaintenanceFrequency',
            size: 90,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'left' },
            Cell: ({ cell, row }) => {
                if (cell.getValue() === '' || cell.getValue() == null) {
                    return '';
                } else if (cell.getValue() === 'Q') {
                    return 'Quarterly';
                } else if (cell.getValue() === 'S') {
                    return 'Semi-Annually';
                } else if (cell.getValue() === 'A') {
                    return 'Annually';
                }
            },
        },
        {
            header: 'Custom Vintage Prefix',
            accessorKey: 'CustomVintagePrefix',
            size: 90,
            muiTableHeadCellProps: { align: 'left' },
            muiTableBodyCellProps: { align: 'left' },
        },
        {
            header: 'Date Created',
            accessorKey: 'DateCreated',
            size: 100,
            // muiTableHeadCellProps: { align: 'left' },
            // muiTableBodyCellProps: { align: 'left' },
            Cell: ({ cell, row }) => formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm'),
        },
        {
            header: 'Date Modified',
            accessorKey: 'DateModified',
            size: 100,
            Cell: ({ cell, row }) => formatISOToDateTimeUnit(cell.getValue(), 'yyyy-MM-dd HH:mm'),
        },
        {
            header: '',
            accessorKey: 'actions',
            size: 80,
            enableHiding: false,
            enableColumnFilter: false,
            muiTableBodyCellProps: { align: 'left' },
            enableColumnActions: false,
            Cell: ({ cell, row }) => (
                <>
                    <IconButton
                        title={'Edit Account'}
                        onClick={() => handleEditAccount(row.original)}
                        sx={{ padding: 0, margin: '0 10px' }}
                    >
                        <EditIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                    <IconButton
                        title={'Delete Account'}
                        onClick={() => handleDeleteAccount(row.original)}
                        sx={{ padding: 0, margin: '0 10px' }}
                    >
                        <DeleteIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                </>
            ),
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ];

    React.useEffect(() => {
        getAllAccountRoles().then((res) => {
            setAccountRoles(res.data);
            refreshAccountTable();
        });

        getVintages().then((res) => {
            let vintageList = [];
            res.data.map((v) =>
                vintageList.push({
                    label: v.UserFriendly,
                    value: v.UserFriendly,
                }),
            );
            vintageList.sort((a, b) => b['value'].localeCompare(a['value'], 'en', { numeric: true }));
            setVintageList(vintageList);
        });
        getVintages(true).then((res) => {
            let vintageList = [];
            res.data.map((v) =>
                vintageList.push({
                    label: v.UserFriendly,
                    value: v.Id,
                }),
            );
            vintageList.sort((a, b) => b['label'].localeCompare(a['label'], 'en', { numeric: true }));
            setPurchasableVintageList(vintageList);
        });
        getCountries().then((res) => {
            let countryList = [];
            res.data.map((v) =>
                countryList.push({
                    label: v.Country,
                    value: v.Code,
                }),
            );
            countryList.sort((a, b) => a['label'].localeCompare(b['label'], 'en', { numeric: true }));
            setCountryList(countryList);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterOpen = () => {
        setFilterOpen(true);
    };

    const handleFilterClose = (option) => {
        const applyFilters = () => {
            let filteredData = [...data];

            // Apply vintage filter
            if (vintageFilter?.value !== null) {
                filteredData = filteredData.filter((item) => item.PurchasedVintage === vintageFilter.value);
            }

            // Apply full data access filter
            if (fullDataAccessFilter?.value !== null) {
                filteredData = filteredData.filter((item) => item.DataPrivilege === fullDataAccessFilter.value);
            }

            setFilteredData(filteredData);
        };

        const resetFilters = () => {
            setVintageFilter(null);
            setFullDataAccessFilter(null);
        };

        switch (option) {
            case 'SAVE':
                applyFilters();
                setFilterOpen(false);
                break;

            case 'RESET':
                resetFilters();
                break;

            case 'CLOSE':
                resetFilters();
                setFilterOpen(false);
                break;

            default:
                break;
        }
    };

    const refreshAccountTable = () => {
        setLoading(true);
        getAccounts().then(
            (res) => {
                res.data?.map((r) => !r.showPassword);
                setData(res.data);
                setFilteredData(res.data);
                setLoading(false);
            },
            () => {
                setData([]);
                setFilteredData([]);
                setLoading(false);
            },
        );
    };

    const handleFormChange = (key, e, setError = undefined) => {
        let t = { ...target };
        const textInputs = ['VintageId', 'MaintenanceFrequency', 'ClientName'];
        if (textInputs.includes(key)) {
            if (e) {
                t[key] = e.target.value;
            } else {
                t[key] = null;
            }
        } else if (key === 'Countries') {
            t[key] = e;
        } else if (key === 'Roles') {
            if (!e.length || (e.length === 1 && e.includes('FUL_DAT_AC'))) {
                t.CustomVintagePrefix = '';
            }
            t[key] = e;
            t['Roles'] = e;
        } else if (key === 'CustomVintagePrefix') {
            validateAndHandleFormChange(t, e, key, setError);
        } else {
            // validates email and client id
            validateAndHandleFormChange(t, e, key, setError);
            t[key] = e.target.value.trim();
        }
        setTarget(t);
    };

    const { Id, ClientId, Roles, Password, EmailAddress, VintageId, MaintenanceFrequency, Countries, CustomVintagePrefix, ClientName } = target;

    const handleEditAccountClose = (option) => {
        if (option === 'SAVE') {
            if (mode === 'Create') {
                let data = {
                    ClientId: ClientId,
                    Password: Password,
                    EmailAddress: EmailAddress,
                    ClientName: ClientName,
                    CustomVintagePrefix: CustomVintagePrefix,
                    Roles: Roles,
                };
                createAccount(data).then(
                    (res) => {
                        if (!Roles.includes('FUL_DAT_AC') && Roles.length) {
                            let vintageData = {
                                AccountId: res.data.Id,
                                VintageId: VintageId,
                                MaintenanceFrequency: MaintenanceFrequency,
                                Countries: Countries.map((c) => c.value).toString(),
                            };
                            createPurchaseHistory(vintageData).then(
                                (res) => {
                                    setTarget(accountClass);
                                    setEditAccountOpen(false);
                                    setMode(null);
                                    refreshAccountTable();
                                },
                                (err) => {
                                    setTarget(accountClass);
                                    setEditAccountOpen(false);
                                    setMode(null);
                                    setAlertOpen(true);
                                    setAlertTitle('Error!');
                                    let message = '';
                                    if (err.response.data) {
                                        message = err.response.data;
                                    }
                                    setAlertContent(message);
                                },
                            );
                        } else {
                            setTarget(accountClass);
                            setEditAccountOpen(false);
                            setMode(null);
                            refreshAccountTable();
                        }
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data) {
                            message = err.response.data;
                        }
                        setAlertContent(message);
                    },
                );
            } else {
                let data = {
                    AccountId: Id,
                    ClientId: ClientId,
                    Password: Password,
                    ClientName: ClientName,
                    EmailAddress: EmailAddress,
                    CustomVintagePrefix: CustomVintagePrefix,
                    Roles: Roles,
                };
                updateAccount(Id, data).then(
                    (res) => {
                        setTarget(accountClass);
                        setEditAccountOpen(false);
                        setMode(null);
                        refreshAccountTable();
                        setResetPassword(false);
                    },
                    (err) => {
                        setAlertOpen(true);
                        setAlertTitle('Error!');
                        let message = '';
                        if (err.response.data) {
                            message = err.response.data;
                        }
                        setAlertContent(message);
                    },
                );
            }
        } else {
            setMode(null);
            setTarget(accountClass);
            setEditAccountOpen(false);
        }
    };

    const handleResetPassword = () => {
        setResetPassword(!resetPassword);
    };

    const handleCreateAccount = () => {
        setMode('Create');
        let acc = { ...accountClass };
        acc.Password = generateRandomPassword(10);
        setTarget(acc);
        setEditAccountOpen(true);
    };

    const handleEditAccount = (data) => {
        setMode('Edit');
        let d = { ...data, Password: '' };

        if (data.Countries) {
            let countries = data.Countries.split(',');
            let selected = countryList.filter((c) => countries.includes(c.value));
            d.Countries = selected;
        } else {
            d.Countries = [];
        }

        setTarget(d);
        setEditAccountOpen(true);
        getAllAccountRoles().then((res) => setAccountRoles(res.data));
        setResetPassword(false);
    };

    const handleDeleteAccount = (data) => {
        setTarget(data);
        setDeleteAccountOpen(true);
    };

    const isFiltered = () => {
        return (vintageFilter && vintageFilter.value) || (fullDataAccessFilter && fullDataAccessFilter.value);
    };

    const handleAccountDeleteClose = (option) => {
        if (option === 'CONFIRM') {
            deleteAccount(target.Id).then(
                (res) => {
                    setDeleteAccountOpen(false);
                    setTarget(accountClass);
                    refreshAccountTable();
                },
                (err) => {
                    setAlertOpen(true);
                    setAlertTitle('Error!');
                    let message = '';
                    if (err.response.data) {
                        message = err.response.data;
                    }
                    setAlertContent(message);
                },
            );
        } else {
            setTarget(accountClass);
            setDeleteAccountOpen(false);
        }
    };

    const handleAlertDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle(null);
        setAlertContent(null);
    };

    const handleOpenFullHistory = (id) => {
        setTargetAccount(id);
        setPurchaseHistoryDialogOpen(true);
    };

    return (
        <>
            <Grid
                container
                item
                xs={12}
            >
                <Grid
                    item
                    xs={1}
                />
                <Grid
                    item
                    xs={12}
                    sx={{ margin: '15px' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={filteredData}
                        positionPagination='both'
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableColumnFilters={false}
                        enableTopToolbar={true}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 15],
                        }}
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                            pagination: { pageSize: 10 },
                            sorting: [{ id: 'ClientId', desc: false }],
                        }}
                        state={{ isLoading: loading }}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                        renderTopToolbarCustomActions={() => (
                            <div>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => refreshAccountTable()}
                                    title={'Refresh'}
                                >
                                    <RefreshIcon />
                                </IconButton>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => handleFilterOpen()}
                                    title={'Show Filters'}
                                >
                                    <FilterAltIcon style={isFiltered() ? { color: '#3c763d' } : {}} />
                                </IconButton>

                                <IconButton
                                    variant='outlined'
                                    title={'Create New Account'}
                                    onClick={() => handleCreateAccount()}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        )}
                    />
                </Grid>
            </Grid>
            <PurchaseHistoryDialog
                purchaseHistoryDialogOpen={purchaseHistoryDialogOpen}
                targetAccount={targetAccount}
                purchasableVintageList={purchasableVintageList}
                countryList={countryList}
                setPurchaseHistoryDialogOpen={setPurchaseHistoryDialogOpen}
                refreshAccountTable={refreshAccountTable}
                setAlertOpen={setAlertOpen}
                setAlertTitle={setAlertTitle}
                setAlertContent={setAlertContent}
            />
            <AccountFilterDialog
                filterOpen={filterOpen}
                handleFilterClose={handleFilterClose}
                vintageList={vintageList}
                vintageFilter={vintageFilter}
                setVintageFilter={setVintageFilter}
                fullDataAccessFilter={fullDataAccessFilter}
                setFullDataAccessFilter={setFullDataAccessFilter}
            />
            <EditAccountDialog
                mode={mode}
                target={target}
                editAccountOpen={editAccountOpen}
                purchasableVintageList={purchasableVintageList}
                countryList={countryList}
                handleFormChange={handleFormChange}
                handleEditAccountClose={handleEditAccountClose}
                handleResetPassword={handleResetPassword}
                resetPassword={resetPassword}
                accountRoles={accountRoles}
            />
            <DeleteAccountDialog
                deleteAccountOpen={deleteAccountOpen}
                target={target}
                handleAccountDeleteClose={handleAccountDeleteClose}
            />
            <AlertDialog
                alertOpen={alertOpen}
                alertTitle={alertTitle}
                alertContent={alertContent}
                handleAlertDialogClose={handleAlertDialogClose}
            />
        </>
    );
};

export default Account;
